/* You can add global styles to this file, and also import other style files */

@import '~bootstrap/scss/bootstrap';
@import '~ngx-toastr/toastr';
@import 'assets/inspinia/style.scss';
@import 'assets/font-awesome/scss/fontawesome.scss';
@import 'assets/font-awesome/scss/regular.scss';
@import 'assets/font-awesome/scss/solid.scss';
@import 'assets/font-awesome/scss/brands.scss';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';



:root {
    --primary: #106bd4;
    --success: #25b455;
    --error: #ED5565;
    --success-light: #25b4552e;
}

// BOOTSTRAP
.dropdown-item {
    width: 100%;
}

// TMP
.file-list li {
    min-height: 40px;
}

// DATATABLE
.closed {
    background-color: var(--success-light);
}

.ngx-datatable .datatable-body .datatable-body-row {
    cursor: pointer;

    .datatable-body-cell-label {
        word-break: break-word;
    }
}

// FORM
.form-control-error {
    display: block;
    margin: -10px 0 15px;
    color:  var(--error);
    font-style: italic;
    font-size: 12px;
}